<template>
  <div class="widget mb-4">
    <!-- <h4 class="text-uppercase color-qua font-bold fs-12">Outubro</h4> -->
    <ul class="list-unstyled list-cards">
      <li class="media block__classic align-items-center">
        <span class="date-time bg-den mr-3">
          <p class="date">{{ data }}</p>
          <p class="time">{{ hora }}</p>
        </span>

        <span class="media-body">
          <p class="title font-bold color-ter">{{ prova.titulo }}</p>
          <p class="fs-14">{{ prova.disciplina.text }}</p>
        </span>
      </li>
    </ul>

    <!-- <h4 class="text-uppercase color-qua font-bold fs-12">Novembro</h4>
    <ul class="list-unstyled list-cards">
      <li class="media block__classic align-items-center">
        <span class="date-time bg-den mr-3">
          <p class="date">14</p>
          <p class="time">8:40</p>
        </span>

        <span class="media-body">
          <p class="title font-bold color-ter">1º Bimestre</p>
          <p class="fs-14">Matemática</p>
        </span>
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  props: {
    prova: {
      type: Object,
    },
  },
  data() {
    return {
      data: "",
      hora: ""
    }
  },
  created() {
    let date = new Date(this.prova.dataHoraInicio.replace("+00:00", ""));

    let date2 = new Date(date.valueOf() - date.getTimezoneOffset() * 60000);

    this.prova.dataHoraInicio = date2;
    this.data = date2.getDate();
    this.hora = date2.getHours() + ":" + date.getMinutes();


    console.log(this.data, this.hora)
    
  },
};
</script>

<style lang="scss" scoped>
</style>


