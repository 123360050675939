<template>
  <table class="block__classic table table__classic table__coming-soon table-striped table-borderless table-hover text-center mb-4">
    <thead>
      <tr>
        <th
          scope="col"
          colspan="4"
        >
          Em breve
        </th>
        <th scope="col">
          Agendado
        </th>
        <th scope="col">
          Ação
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="prova in provas"  :key="prova.id"   >        
          <td class="text-left pl-4">
            {{ prova.disciplinaText }} 
          </td>
          
          <td>{{ prova.turmaTitulo }}</td>          
          <td><strong>{{ prova.turmaAnoTitulo }}</strong></td>
          
          <td>
             <ProvaStatus :status="{codigo: prova.statusCodigo, descricao: prova.statusDescricao}" />
          </td>
         
          <td class="date">
           {{ prova.dataHoraInicio | formatDate }} 
          </td>
         <td>
            <MenuTableOptions :prova="prova" />
          </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th colspan="7">
          <i class="far fa-clock" /> Início em  
        </th>
      </tr>
    </tfoot>
  </table>
</template>

<script>
 import { constantes } from "@/constants";
import MenuTableOptions from "@/components/menu/MenuTableOptions";
import ProvaStatus from '@/components/provas/ProvaStatus'
import moment from 'moment';

export default {
  components:{
      MenuTableOptions,
     ProvaStatus
  },
  props:{ 
    provas: null    
  }, 
  data() {
    return {
      visible: true,
      horaProva: null
    }
  },
  filters:{     
      formatDate(value) {
        if (value) {
          return moment(String(value)).format('DD/MM/YYYY hh:mm')        
        }else{
          return '-'
        }
      },
      formatHora(value) {
        if (value) {
          return moment(String(value)).format('hh:mm:ss')        
        }else{
          return '-'
        }
      }
  },
  created() {
      console.log(this.provas)
  }
}

</script>

<style lang="scss">

</style>


