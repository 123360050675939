<template>
  <table class="block__classic table table__classic table-striped table-borderless table-hover text-center mb-4">
    <thead>
      <tr>
        <th
          scope="col"
          colspan="3"
        >
          {{ title }}
        </th>
        <th scope="col">
          Realizado
        </th>
        <th scope="col">
          Status
        </th>
        <th scope="col">
          Ação
        </th>
        <th scope="col" />
      </tr>
    </thead>
    <tbody>
      <tr v-for="prova in provas"  :key="prova.id"  >        
          <td class="text-left pl-4">
            {{ prova.disciplinaText }} 
          </td>
          
          <td>{{ prova.turmaTitulo }}</td>
          <td><strong>{{ prova.turmaAnoTitulo }}</strong></td>
          <td>20/04/2020 - 8:00</td>
          <td>
             <ProvaStatus :status="{codigo: item.statusCodigo, descricao: item.statusDescricao}" />
          </td>
          <td>
            <MenuTableOptions :prova="prova" />
          </td>
          <td class="date">
            15/04/2020 <br> 9:23
          </td>
        
      </tr>
    </tbody>
    <tfoot>
      <tr v-show="path!='disponibilizar'">
        <th colspan="8">
          <router-link
            :to="{ name: 'provas_com_filtro', params: { filter:path }}"
            class="link font-bold"
          >
            + {{ buttonMore }}
          </router-link>
        </th>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import MenuTableOptions from "@/components/menu/MenuTableOptions";
import ProvaStatus from '@/components/provas/ProvaStatus'

export default {
  components:{
    MenuTableOptions,
    ProvaStatus
  },
  props:{
    title: String,
    buttonMore: String,
    path: String,
    provas: null,
    status:null
  },   
  data() {
    return {
      visible: true,
       
    }
  },
  created() {
    console.log(this.provas)
  },
  methods:{
    showMore() {},
    
  }
}

</script>

<style lang="scss" scoped>

</style>


